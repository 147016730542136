import React from 'react'
import PickMeals from '../../assests/pick-meals-image.png'
import ChooseMeals from '../../assests/choose-image.png'
import DeliveryMeals from '../../assests/delivery-image.png'


const Work = () => {
    const workInfoData = [
        {
            image: PickMeals,
            title: "Pick Meals",
            text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Vero optio sed consectetur doloremque voluptatibus quas."
        },
        {
            image: ChooseMeals,
            title: "Choose How Often",
            text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Vero optio sed consectetur doloremque voluptatibus quas."
        },
        {
            image: DeliveryMeals,
            title: "Fast Deliveries",
            text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Vero optio sed consectetur doloremque voluptatibus quas."
        }
    ]
  return (
    <div className='work-section-wrapper'>
        <div className='work-section-top'>
            <p className='primary-heading'>Work</p>
            <h1 className='primary-heading'>How It Works</h1>
            <p className='primary-text'>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolorum consequuntur, vel sit laboriosam minima eligendi accusamus sint quasi velit fugiat.
            </p>
        </div>
        <div className='work-section-bottom'>
            {workInfoData.map((data)=> (
                <div className='work-section-info'>
                    <div className='info-boxes-img-container'>
                        <img src={data.image} alt="" />
                        <h2>{data.title}</h2>
                        <p>{data.text}</p>
                    </div>
                </div>
            ))}
        </div>
      
    </div>
  )
}

export default Work
