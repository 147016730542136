import React from 'react'
import './Home.css'
import Navbar from '../Navbar/Navbar'
import BannerBackground from '../../assests/home-banner-image.png'
import {FiArrowRight} from 'react-icons/fi';
import BannerImage from '../../assests/home-banner-background.png'

const Home = () => {
  return (
    <div className='home-container main-banner'>
      <Navbar/>
        <div className='home-banner-container'>
            <div className='home-bannerImage-container'>
                <img src={BannerBackground}/>
            </div>
            <div className='home-text-section'>
                <h1 className='primary-heading'>
                    Your Favourite Food Delivered Hot & Fresh
                </h1>
                <p className='primary-text'>
                    Healthy switcher chefs do all the prep work, like peeding, 
                    chopping
                    & marinating, so you can cook a fresh food.
                </p>
                <button className='secondary-button'>
                    Order Now <FiArrowRight/>
                </button>
            </div>
            <div className='my'>
                <img src={BannerImage} alt="" />
            </div>
        </div> 
    </div>
  )
}

export default Home
