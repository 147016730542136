import React from 'react'
import AboutBackground from '../../assests/about-background.png'
import AboutBackgroundImage from '../../assests/about-background-image.png'
import {BsFillPlayCircleFill} from 'react-icons/bs'
const About = () => {
  return (
    <div className='about-section-container'>
        <div className='about-background-image-container'>
            <img src={AboutBackground} />
        </div>
        <div className='about-section-image-container'>
            <img src={AboutBackgroundImage}/>
        </div>
        <div className='about-section-text-container'>
            <p className='primary-text'>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Officiis dignissimos quas error eveniet, impedit facilis cum delectus fuga corrupti voluptas hic repudiandae non laborum eligendi!
            </p>
            <p className='primary-text'>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Distinctio, similique error consectetur atque soluta voluptatum natus?
            </p>
            <div className='about-buttons-container'>
                <button className='secondary-button'>Learn More</button>
                <button className='watch-video-button'><BsFillPlayCircleFill/>
                {" "}Watch Vedio</button>

            </div>
        </div>

      
    </div>
  )
}
export default About
